













































































































































































































































































































import { Bar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js'

import { getElasticSearchClient } from '@/services/elasticSearch'
import DatePicker from '@/components/reports/DatePicker.vue'
import GroupByIntervalSelect from '@/components/reports/GroupByIntervalSelect.vue'
import ManageFilters from '@/components/reports/ManageFilters.vue'
import { CurrencyEnum } from '../../../../shared/types/types'
import { formatPrice, getCountryName } from '../../lib/utils'
import DatePickerMixin from '../../mixins/DatePicker.mixin'
import { AndFilterGroup, Filter, IntervalEnum } from '../../lib/reports/types'
import {
  elasticSearchFilterQuery,
  filterPriceLabel,
  filterStartCase,
  filterValueLabel,
  totalSalesBackgroundOpacity,
} from '../../lib/reports/functions'
import CurrencySelect from '../../components/shared/inputs/CurrencySelect.vue'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

interface Aggr {
  key: string
  doc_count: number
  countryName: string
  sumGrossSales: { value: number }
  sumDiscounts: { value: number }
  sumReturns: { value: number }
  sumNet: { value: number }
  sumFee: { value: number }
  sumTotalChargebackFee: { value: number }
  sumTotalChargebacks: { value: number }
  sumShipping: { value: number }
  sumTax: { value: number }
  netSales: { value: number }
  totalSales: { value: number }
}

export default DatePickerMixin.extend({
  name: 'Location',
  components: {
    Bar,
    CurrencySelect,
    DatePicker,
    GroupByIntervalSelect,
  },
  filters: {
    filterStartCase,
    filterValueLabel,
    filterPriceLabel,
  },
  data() {
    return {
      currency: CurrencyEnum.Usd as CurrencyEnum,
      switch: false,
      timeInterval: IntervalEnum.Day,
      dataFilters: [
        {
          orGroups: [
            { filter: 'ordersPaymentStatus', operator: 'is', values: ['paid'] },
            {
              filter: 'ordersPaymentStatus',
              operator: 'is',
              values: ['partially_refunded'],
            },
            {
              filter: 'ordersPaymentStatus',
              operator: 'is',
              values: ['refunded'],
            },
          ],
        },
      ] as AndFilterGroup[],
      data: null as Record<string, any> | null,
      visibleColumns: [
        'key',
        'count',
        'grossSales',
        'discounts',
        'returns',
        'fee',
        'totalChargebackFee',
        'totalChargebacks',
        'net',
        'tax',
        'shipping',
        'totalSales',
      ] as string[],
    }
  },
  computed: {
    query(): Record<string, any> {
      const fieldPrefix = this.currency === CurrencyEnum.Usd ? 'usdPrices.' : ''
      return {
        aggs: {
          byCountry: {
            terms: {
              field: 'customer.billingAddress.countryCode',
              // show_term_doc_count_error: true
              size: 10000,
            },
            aggs: {
              sumGrossSales: {
                sum: {
                  field: `${fieldPrefix}originalSubTotalPrice.amount`,
                },
              },
              sumDiscounts: {
                sum: {
                  field: `${fieldPrefix}totalDiscountPrice.amount`,
                },
              },
              sumReturns: {
                sum: {
                  field: `${fieldPrefix}totalRefund.amount`,
                },
              },
              sumNet: {
                sum: {
                  field: `${fieldPrefix}netPayment.amount`,
                },
              },
              sumFee: {
                sum: {
                  field: `${fieldPrefix}fee.amount`,
                },
              },
              sumTotalChargebackFee: {
                sum: {
                  field: `${fieldPrefix}totalChargebackFee.amount`,
                },
              },
              sumTotalChargebacks: {
                sum: {
                  field: `${fieldPrefix}totalChargebacks.amount`,
                },
              },
              sumShipping: {
                sum: {
                  field: `${fieldPrefix}shippingPrice.amount`,
                },
              },
              sumTax: {
                sum: {
                  field: `${fieldPrefix}taxPrice.amount`,
                },
              },
            },
          },
        },
        size: 0,
        query: elasticSearchFilterQuery(this.dataFilters, this.datePicker),
      }
    },
    rows(): Aggr[] {
      const buckets = this.data?.aggregations.byCountry.buckets ?? []
      for (const bucket of buckets) {
        bucket.countryName = getCountryName(bucket.key)
        bucket.totalSales = { value: this.totalSales(bucket) }
        bucket.netSales = { value: this.netSales(bucket) }
      }
      return buckets
    },
    sum(): Aggr {
      return this.rows.reduce(
        (acc: Aggr, b: Aggr) => {
          acc.sumGrossSales.value =
            acc.sumGrossSales.value + b.sumGrossSales.value
          acc.sumDiscounts.value = acc.sumDiscounts.value + b.sumDiscounts.value
          acc.sumReturns.value = acc.sumReturns.value + b.sumReturns.value
          acc.sumFee.value = acc.sumFee.value + b.sumFee.value
          acc.sumTotalChargebackFee.value =
            acc.sumTotalChargebackFee.value + b.sumTotalChargebackFee.value
          acc.sumTotalChargebacks.value =
            acc.sumTotalChargebacks.value + b.sumTotalChargebacks.value
          acc.sumNet.value = acc.sumNet.value + b.sumNet.value
          acc.sumShipping.value = acc.sumShipping.value + b.sumShipping.value
          acc.sumTax.value = acc.sumTax.value + b.sumTax.value
          acc.netSales.value = acc.netSales.value + b.netSales.value
          acc.totalSales.value = acc.totalSales.value + b.totalSales.value
          acc.doc_count = acc.doc_count + b.doc_count
          return acc
        },
        {
          sumGrossSales: { value: 0 },
          sumDiscounts: { value: 0 },
          sumReturns: { value: 0 },
          sumNet: { value: 0 },
          sumFee: { value: 0 },
          sumTotalChargebackFee: { value: 0 },
          sumTotalChargebacks: { value: 0 },
          sumShipping: { value: 0 },
          sumTax: { value: 0 },
          netSales: { value: 0 },
          totalSales: { value: 0 },
          doc_count: 0,
        } as Aggr
      )
    },
    chartData(): Record<string, any> {
      return {
        labels: this.rows.map((row) => getCountryName(row.key)),
        datasets: [
          {
            label: 'Total Sales',
            backgroundColor: '#01A19F',
            data: this.rows.map((row) => this.totalSales(row)),
          },
        ],
      }
    },
    moneyChartOptions() {
      return {
        responsive: true,
        minBarLength: 5,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              stepSize: 100,
              callback: (value: any) =>
                formatPrice({
                  amount: value * 100,
                  currency: this.currency,
                }),
            },
          },
        },
      }
    },
    totalSalesBackgroundOpacity(): Record<number, number> {
      return totalSalesBackgroundOpacity<Aggr>(this.rows, this.totalSales)
    },
  },
  watch: {
    datePicker: {
      handler(): void {
        this.fetchData()
      },
      deep: true,
    },
    timeInterval(): void {
      this.fetchData()
    },
    dataFilters: {
      handler(): void {
        this.fetchData()
      },
      deep: true,
    },
    currency: {
      immediate: true,
      handler(currency): void {
        if (currency === CurrencyEnum.Usd) {
          let hasTotalPriceCurrency = false
          for (const andGroup of this.dataFilters) {
            for (const orGroup of andGroup.orGroups) {
              if (orGroup.filter === 'totalPrice.currency') {
                hasTotalPriceCurrency = true
              }
            }
          }
          if (hasTotalPriceCurrency) {
            this.dataFilters = this.dataFilters.filter((andGroup) => {
              return !andGroup.orGroups.some(
                (orGroup) => orGroup.filter === 'totalPrice.currency'
              )
            })
          }
        } else {
          let hasTotalPriceCurrency = false
          for (const andGroup of this.dataFilters) {
            for (const orGroup of andGroup.orGroups) {
              if (orGroup.filter === 'totalPrice.currency') {
                hasTotalPriceCurrency = true
                orGroup.values = [currency]
              }
            }
          }
          if (!hasTotalPriceCurrency) {
            this.dataFilters.push({
              orGroups: [
                {
                  filter: 'totalPrice.currency',
                  operator: 'is',
                  values: [currency],
                },
              ],
            })
          }
        }
      },
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    getCountryName(countryCode: string): string {
      return getCountryName(countryCode)
    },
    formatPrice(price: number): string {
      return formatPrice({ amount: price, currency: this.currency })
    },
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post('/orders/_search', this.query)
      this.data = data
    },
    onRangeChanged(range: { startDate: Date; endDate: Date }): void {
      this.datePicker.startDate = range.startDate
      this.datePicker.endDate = range.endDate
    },
    openManageFilters(): void {
      this.$buefy.modal.open({
        parent: this,
        customClass: 'manage-filters',
        scroll: 'keep',
        component: ManageFilters,
        hasModalCard: true,
        props: {
          initFilters: this.dataFilters,
        },
        trapFocus: true,
        events: {
          value: (value: AndFilterGroup[]): void => {
            this.dataFilters = JSON.parse(
              JSON.stringify(value)
            ) as AndFilterGroup[]
          },
        },
      })
    },
    removeAndGroup(group: AndFilterGroup) {
      this.dataFilters.splice(this.dataFilters.indexOf(group), 1)
    },
    removeOrGroup(andGroup: AndFilterGroup, orGroup: Filter) {
      if (andGroup.orGroups.length === 1) {
        // If there are more and groups, but there is only one orGroup inside then remove the andGroup
        this.removeAndGroup(andGroup)
      } else {
        andGroup.orGroups.splice(andGroup.orGroups.indexOf(orGroup), 1)
      }
    },
    netSales(row: Aggr) {
      return (
        row.sumGrossSales.value - row.sumDiscounts.value - row.sumReturns.value
      )
    },
    totalSales(row: Aggr) {
      return (
        (this.netSales(row) +
          row.sumShipping.value -
          row.sumFee.value +
          row.sumTotalChargebacks.value -
          row.sumTotalChargebackFee.value +
          row.sumTax.value) /
        100
      )
    },
  },
})
